import styled, { css } from 'styled-components'
import theme from 'common/style/styleSetup'
export const Header = styled.div`
  border-top: 1px solid var(--Gold-stroke, #ffb800);
  border-bottom: 1px solid var(--Gold-stroke, #ffb800);
  background: linear-gradient(
    270deg,
    rgba(180, 27, 44, 0) 0%,
    #9f091a 52%,
    rgba(180, 27, 44, 0) 100%
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 768px) {
    display: none;
  }
  /* body .navbar-overlay { */
  /* @media screen and (max-width: 768px) {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0; */
  /* width: 100%;
      height: 100%; */
  /* width: 100vw;
    height: 100vh; */
  /* visibility: visible; */
  /* opacity: 1; */
  /* transition: 0.3s; */
  /* } */
  /* } */
`

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin: 0px;
  }
`
export const Row = styled.div`
  display: flex;
`
export const Menu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const Info = styled.div`
  color: yellow;
  margin-left: 30px;
  cursor: pointer;
  img {
    width: 20px;
  }
  span {
    color: white;
  }
`
export const Content = styled.div`
  display: flex;
  height: 30px;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove yellow;
  border-radius: 10px;
  width: 100px;
  justify-content: center;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: olive;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em olive;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const ContentLogOut = styled.div`
  display: flex;
  height: 30px;
  width: 100px;
  justify-content: center;
  align-items: center;
  filter: brightness(0.85);
  border: 3px groove red;
  border-radius: 10px;
  p {
    margin: 0;
    font-weight: 300;
  }
  padding: 5px;
  margin: 2px;
  span {
    color: white !important;
  }
  :hover {
    filter: brightness(1.5);
  }

  ::before {
    content: '';
    position: absolute;
    width: 10px;
    height: 100%;
    background-color: #800000;
    transform: skewX(-1deg);
    transform-origin: right center;
    opacity: 0;
    left: -1em;
    transition: opacity 0.3s ease-in-out;
    box-shadow: 0 0 0.4em #800000;
  }
  :hover::before,
  :hover::after {
    opacity: 0.9; /* Set opacity to 0 to disable the shadow effect */
  }
  cursor: pointer;
  :hover p {
    font-size: 18px;
  }
`
export const AvatarImg = styled.div`
  text-algin: center;
  background: white;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  justify-content: center;
  display: flex;
  margin: 0px;
  img {
    width: 35px;
    height: 35px;
    margin-top: 3px;
    cursor: pointer;
  }
`
export const Wrapper = styled.div`
  width: 100%;
  border-top: solid 1px 1px ${theme.bg[9]};
  border-bottom: solid 1px 1px ${theme.bg[9]};
  background-image: linear-gradient(#23344a, #13253e);
  box-shadow: inset 0 3px 0 #0e151e;
  background: #2c2c2c;
`
export const NewWrapper = styled.div`
  width: 100%;
  background: #2c2c2c;
`

export const WrapperAuth = styled.div`
  position: relative;
  z-index: 10;
  max-width: 1270px;
  margin: auto;
  // padding: 15px 0;
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  align-items: center;
  padding-right: 2%;

  .btn {
    cursor: pointer;
    width: 100%;
    max-width: 150px;
    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`

export const WrapperTop = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  z-index: 100;
  max-width: 1270px;
  height: 90px;
  @media screen and (max-width: 1250px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding: 0px;
    margin: 0 0 0 0;
    gap: 0px;
  }
  @media screen and (max-width: 768px) {
    height: 120px;
    flex-direction: row;
  }
  position: static;
`

export const MenuWraper = styled.div`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  @media screen and (min-width: 768px) {
    display: none;
  }
`
export const MenuGroupList = styled.div<{ show?: boolean | undefined }>`
  /* padding: 12px 12px;
  background-color: white;
  border: 4px solid green; */
  /* @media screen and (min-width: 768px) {
    display: none;
  } */
  ${(props) =>
    props.show &&
    css`
      @media screen and (max-width: 768px) {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 4;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        visibility: visible;
        opacity: 1;
        transition: 0.3s;
      }
    `}
`

export const TopLogo = styled.div`
  width: 100%;
  max-width: 180px;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  @media screen and (max-width: 1024px) {
    max-width: 200px;
    min-width: 200px;
  }
  img {
    width: 100%;
    animation: logoAkAnim 8s ease infinite;
    height: auto;
  }
`
export const TopButton = styled.div<{ show?: boolean | undefined }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  .content-top {
    position: relative;
    font-weight: 600;
    color: ${theme.color[0]};
    justify-content: center;
    display: flex;
    align-items: center;
    /* margin: 0 auto; */
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    width: 100%;
    max-width: 200px;
    white-space: nowrap;

    .img-box {
      justify-content: center;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 15px;

      .simple {
        display: block;
      }

      .hover {
        display: none;
      }

      &.event {
        .img {
          max-width: 40px;
          img {
            width: 100%;
          }
        }
      }
      span {
        color: #fff;
        font-size: 1.7em;
        font-style: 100%;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
          font-size: 1.2em;
        }
      }
      img {
        @media screen and (max-width: 1024px) {
          width: 30px;
        }
      }
    }
    .active-img {
      display: none;
    }
    // &.large-icon {
    //   img {
    //     margin-top: 10px;
    //   }
    // }
    &.logout {
      background: ${theme.bg[11]};
      border: solid 1px ${theme.bg[14]};
      border: none;
    }
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-right: 2px solid var(--Gold-stroke, #ffb800);
      border-left: 2px solid var(--Gold-stroke, #ffb800);
      z-index: 1;
      display: none;
    }

    :hover,
    &.active {
      // background: ${theme.bg[13]};

      .img-box {
        // gap: 5px;

        .simple {
          display: none;
          width: 40px;
        }

        .hover {
          display: block;
          width: 40px;
        }

        span {
          background: var(
            --Gold-stroke,
            linear-gradient(180deg, #ffb800 0%, #feeac3 52.08%, #ffb200 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-weight: 500;
        }

        img {
          // filter: brightness(0) invert(0);
          // transform: scale(1.2);
        }
      }

      cursor: pointer;
      &.logout {
        background: ${theme.bg[12]};
      }
    }
    .fa {
      margin: -3px 4px 0 0;
    }
  }
  @media screen and (max-width: 1250px) {
    // display: none;
  }
  @media screen and (max-width: 768px) {
    display: none;
    ${(props) =>
      props.show &&
      css`
        display: flex;
        position: fixed;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 24px;
        align-items: flex-start;
        top: 0;
        padding: 24px;
        left: 1px;
        width: max-content;
        height: 100%;
        z-index: 5;
        background-color: black;
      `}
  }
`

export const WrapperBot = styled.div`
  max-width: 1350px;
  width: 100%;
  height: 46px;
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin: 0 auto;
  .item {
    color: ${theme.color[7]};
    width: 10%;
    height: 24px;
    padding: 0 12px;
    text-align: center;
    border-left: 1px solid ${theme.bg[15]};
    justify-content: center;
    display: flex;
    align-items: center;
    :nth-child(10) {
      border-right: 1px solid ${theme.bg[15]};
    }
    .content {
      width: 100%;
      height: 28px;
      padding-top: 1px;
      line-height: 28px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      :hover {
        background: ${theme.bg[16]};
        border: solid 1px ${theme.bg[17]};
      }
    }
  }
`

export const UserTag = styled.div`
  display: flex;
  min-width: 180px;
  align-items: center;
  justify-content: center;
  background: #101010;
  gap: 9px;
  padding: 8px 20px;
  cursor: pointer;
  position: relative;
  border-radius: 30px;
  border: 2px solid var(--Gold-stroke, #ffb800);

  background: #101010;
  span {
    color: #fff;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
  }
  .icon {
    width: 25px;
  }
  &:hover {
    .cash-info {
      display: block;
    }
  }
  .cash-info {
    display: none;
    position: absolute;
    border: 2px solid var(--Gold-stroke, #feeac3);
    background: #101010;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    padding: 14px;
    z-index: 100;
    min-width: 300px;
    top: calc(100% + 5px);
    .item {
      display: flex;
      justify-content: space-between;
      .title {
        color: #fff;
        width: 33%;
      }
      .value {
        background: var(
          --Button-hover,
          linear-gradient(180deg, #f00 0%, #ffd02b 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
      }
    }
  }
`

export const InfoUser = styled.div`
  display: flex;

  .cash-info {
    // background: #101010;
    font-size: 20px;
    font-weight: 500;
    line-height: normal;
    padding: 14px;
    z-index: 100;
    min-width: 300px;
    .item {
      display: flex;
      justify-content: space-between;
      .title {
        color: #fff;
        width: 33%;
      }
      .value {
        background: var(
          --Gold-stroke,
          linear-gradient(180deg, #ffb800 0%, #feeac3 52.08%, #ffb200 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
      }
    }
  }
`

export const BtnMenu = styled.div`
  cursor: pointer;
  margin-left: 24px;
`

export const BoxItem = styled.div`
  height: 100%;

  .img-box {
    img {
      width: 40px;
      object-fit: cover;
    }
  }
`
export const BoxItemP = styled.p``
