import langJapanese from 'common/constants/LangJpAlert'

const TranslateLang = (str: string) => {
  for (const key in langJapanese) {
    if (str.includes(key)) {
      let regex = /(\S+?)\((\S+?)\)(.*)/
      if (/\(|\)/.test(str)) {
        if (str.includes('[') || str.includes(']')) {
          regex = /(\S+?)\[(\S+?)\](.*)/
        }
        const [, firstPart, secondPart, thirdPart]: any = str.match(regex)

        return `${langJapanese[firstPart]} ${secondPart} ${langJapanese[thirdPart]}`
      }

      if (langJapanese[key]) return str.replace(key, langJapanese[key])
      return str
    }
  }

  return str
}

export default TranslateLang
