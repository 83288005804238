const langJapanese: any = {
  '회원가입이 완료되었습니다.\\n\\n관리자 승인 후 로그인하실 수 있습니다.':
    '会員登録が完了しました\n\n管理者承認後にログインできます。',
  '처리가 완료되었습니다': '処理完了しました',
  '잘못된 방식으로 접근하셨습니다': '間違った経路で接近しました',
  '접속이 차단된 IP입니다': '接続が遮断されたIPです',
  '로그인 자동방지 숫자를 맞게 넣어주세요':
    'ログイン自動防止の数字を正しく入れてください',
  '비밀번호 5회 오류로 접속이 제한되었으니 관리자에게 문의 바랍니다':
    'パスワード5回エラーでアクセスが制限されましたので、管理者にお問い合わせください',
  '로그인 정보를 다시 확인해주세요': 'ログイン情報を再確認してください',
  '관리자 승인 후 로그인할 수 있습니다':
    '管理者承認後、ログインすることができます',
  '접속이 차단되었습니다': '接続が遮断されました',
  '회원가입이 완료되었습니다': '会員登録が完了しました',
  '은행을 선택해 주세요': '銀行を選択してください',
  '하이픈(-)없이 숫자만 입력해주세요':
    'ハイフン(-)なしで数字だけ入力してください',
  '회원전화번호를 확인해주세요': '会員電話番号をご確認ください',
  '바르지않은 번호입니다': '正しくない番号です',
  '아이디를 입력하셔야 합니다': 'IDを入力してください',
  '는 이미 가입되어 있는 아이디입니다': 'はすでに加入されているIDです',
  '비밀번호는 숫자와영문, 특수문자를 혼용하여야 합니다':
    'パスワードは数字と英文、特殊文字を混用してください',
  '는 이미 가입되어 있는 닉네임입니다.': 'すでに加入しているニックネームです',
  '추천인을 입력하셔야 합니다': '推薦人を入力する必要があります',
  '추천인을 확인해주세요': '推薦人を確認してください',
  '는 등록되어 있지 않은 추천인 코드입니다.':
    ' は登録されていない推薦人コードです',
  '이미 가입되어 있는 계좌번호입니다': 'すでに加入している口座番号です',
  '이미 가입되어 있는 핸드폰번호입니다': 'すでに加入している携帯番号です',
  '는 사용가능한 아이디입니다': 'は使用可能なIDです',
  '을 사용하실 수 있습니다': 'を使用することができます',
  '닉네임 입력하셔야 합니다': 'ニックネームを入力してください',
  '는 이미 가입되어 있는 닉네임입니다':
    'は、すでに登録されているニックネームです',
  '추천인 코드가 확인되었습니다': '推薦人コードが確認されました',
  '잘못된 추천인 코드입니다..': '間違った推薦人コードです',
  '등록이 안된 추천인 코드입니다': '登録されていない推薦人コードです',
  '인증되지 않은 계정입니다': '認証されていないアカウントです',
  '수정이 완료되었습니다': '修正が完了しました',
  '현재 비밀번호를 잘못입력하셨습니다': '現在のパスワードを誤って入力しました',
  '비밀번호가 서로 같지 않습니다': 'パスワードが互いに同じではありません',
  '환전신청이 완료되었습니다': '出金申請が完了しました',
  '까지 은행점검시간입니다': 'まで銀行点検時間です',
  '환전금액은 최소': '出金額は最低',
  '원이상만 가능합니다.': 'ウォン以上のみ可能です',
  '최소 1만 원부터 1만 원 단위로 신청해 주십시오':
    '最低1千円から1千円単位で申し込んでください',
  '환전은 하루 최대': '出金は1日最大',
  '회까지만 가능합니다': '回までしかできません',
  '환전처리중인 데이터가 있어 추가 환전신청을 하실 수 없습니다':
    '出金処理中のデータがあるため、追加の出金申請はできません',
  '환전신청 이후': '出金申請後',
  '분 후에 다시 환전신청이 가능합니다': '分後に再度出金申請が可能です',
  '현재 환전신청을 할 수 없습니다': '現在、出金申請ができません',
  보유머니: '保有マネー',
  '가 부족하여 환전신청에 실패하였습니다':
    'が不足しているため、出金申請に失敗しました',
  '환전비밀번호를 잘못입력하셨습니다': '出金パスワードを間違って入力しました',
  '충전금액의100%이상 배팅하신 후 환전신청을 하실 수 있습니다':
    '入金金額の100%以上を賭けた後、出金申請をすることができます。',
  '충전신청이 완료되었습니다.': '入金申請が完了しました',
  '충전금액은 최소': '入金金額は最小',
  충전신청은: '入金申請は',
  '원단위로만 가능합니다': '円単位でのみ可能です',
  '충전은 하루 최대': '入金は1日最大',

  '충전신청된 내역이 있습니다': '入金申請された内訳があります',
  '선택하신 내역이 삭제되었습니다.': '選択した履歴が削除されました',
  '승인 또는 취소된 내역만 삭제가능합니다':
    '承認またはキャンセルされた履歴のみ削除可能です',
  '잘못된 경로로 접근하셨습니다': '間違った経路で接近されました',
  '환전완료내역이 모두 삭제되었습니다': '出金完了内訳がすべて削除されました',
  '데이터를 찾지 못했습니다': 'データが見つかりませんでした',
  '배팅이 완료되었습니다': 'ベティングが完了しました',
  '배팅할 경기를 선택하세요': 'ベティングする試合を選択してください',
  '배팅이 불가 합니다. 고객센터로 문의 바랍니다':
    'ベティングができません。 カスタマーセンターまでお問い合わせください',
  '금액을 확인해주세요': '金額を確認してください',
  최소: '最低',
  円以上バッティングする必要があります: '円以上バッティングする必要があります',

  '배팅을 하실 수 없습니다': 'ベティングができません',
  '등록된 목록이 없습니다.': '登録されたリストがありません',
  '글읽기 권한이 없습니다.': '文を読む権限がありません',
  '데이터를 찾지 못했습니다.': 'データが見つかりませんでした',
  '페이지를 찾을수 없습니다.': 'ページが見つかりません',
  '배팅삭제가 완료되었습니다.': 'ベッティングの削除が完了しました',
  '진행중인 배팅내역은 삭제하실 수 없습니다.':
    '進行中のベット履歴は削除できません',
  '잘못된 경로로 접근하셨습니다.': '間違った経路で接近しました',
  '등록이 완료되었습니다.': '登録が完了しました',
  '회원승인후 변경가능합니다.': '会員承認後に変更できます',
  '는 이미 가입되어 있는 아이디입니다.': 'すでに加入されているIDです',
  '하이픈(-)없이 숫자만 입력해주세요.':
    'ハイフン(-)なしで数字だけ入力してください',
  '회원전화번호를 확인해주세요.': '会員電話番号をご確認ください',
  '바르지않은 번호입니다.': '正しくない番号です',
  '비밀번호는 숫자와영문,특수문자를 혼용하여야 합니다.':
    'パスワードは数字と英文、特殊文字を混用してください',
  '처리 완료 되었습니다.': '処理完了しました',
  '권한이 없습니다.': '権限がありません',
  '롤링금은 0보다 크거나 같아야 합니다.':
    'ローリング金は0より多くなければなりま',
  '정산금은 100보다 작거나 같아야 합니다':
    '精算金は100より少なくなければなりま',
  '롤링금은 10보다 작거나 같아야 합니다.':
    'ローリング金は10より少なくなければなりま',
  '상부설정을 먼저 하세요.': '上部設定を先に行ってください',
  '상부보다 롤링값이 작아야 합니다':
    '上部よりローリング値が少なくなければなりません',
  '하부보다 롤링값이 커야 합니다.':
    '下部よりもローリング値が多くなければなりません。',
  '처리가 완료되었습니다.': '処理が完了しました',
  '하부회원만 가능합니다.': '下部会員のみ可能です',
  '충전값은 숫자만 가능합니다.': '入金値は数字のみ可能です',
  '충전, 환전중 한가지씩 처리가능합니다.':
    '入金、出金のどちらか一つずつ処理可能です',
  '충전, 환전금액은 0원 이상이어야 합니다.':
    '入金、出金額は0ウォン以上でなければなりません',
  '충전, 환전 금액이 없습니다.': '入金、出金額がありません',
  '보유금액보다 많은 금액은 충전이 불가능합니다.':
    '保有金額より多い金額は入金できません',
  '보유금액보다 많은 금액은 환전이 불가능합니다.':
    '保有金額より多い金額は出金ができません',
  '전환처리가 완료되었습니다.': '切り替え処理が完了しました',
  '포인트 전환할 금액을 입력하세요.': 'ポイント転換する金額を入力してください',
  '포인트 전환은': 'ポイント転換は',
  '점 이상 전환이 가능합니다.': 'ポイント以上転換可能です',
  '전환금액은 보유포인트': '転換金額は保有ポイント',
  '까지만 가능합니다.': 'までです',
  '입금할 금액을 입력하세요': '入金する金額を入力してください',
  '비밀번호를 입력해 주세요': 'パスワードを入力してください。',
  '환전비밀번호를 잘못입력하셨습니다.': '出金パスワードを間違って入力しました.',
  유머니: '保有金',
  '가 부족하여 환전신청에 실패하였습니다.': '不足のため出金申請に失敗しました',
  '환전신청이 완료되었습니다.': '出金申請が完了しました.',
  '문의에 대한 답변 후 등록가능합니다.':
    'お問い合わせに対する回答後、登録可能です。',
  '충전신청된 내역이 있습니다.': '入金申請された内訳があります。',
  '18:00~18:10까지 은행점검시간입니다': '18:00~18:10まで銀行の点検時間です',
  '계좌번호가 고객센터 문의에 등록되었습니다.\n\n관리자 확인 후 계좌번호를 답변글로 남기겠습니다.':
    '口座番号がカスタマーセンターのお問い合わせに登録されました。\n\n管理者の確認後、口座番号をメッセージでお伝えします。',
  '회원아이디를 확인바랍니다': 'パスワードを確認してください。',
  '비밀번호가 일치하지 않습니다.': 'パスワードが一致しません。',
  '5회 연속 비밀번호 오류시 계정이 차단됩니다. [남은횟수? 1회]':
    ' 5回連続パスワードエラーの場合、アカウントがブロックされます。[残り回数1回]',
  '5회 연속 비밀번호 오류시 계정이 차단됩니다. [남은횟수? 2회]':
    ' 5回連続パスワードエラーの場合、アカウントがブロックされます。[残り回数2回]',
  '5회 연속 비밀번호 오류시 계정이 차단됩니다. [남은횟수? 3회]':
    ' 5回連続パスワードエラーの場合、アカウントがブロックされます。[残り回数3回]',
  '5회 연속 비밀번호 오류시 계정이 차단됩니다. [남은횟수? 4회]':
    ' 5回連続パスワードエラーの場合、アカウントがブロックされます。[残り回数4回]',
  '비밀번호 5회 오류로 접속이 제한되었으니 \\n\\n관리자에게 문의 바랍니다.':
    'パスワード5回のエラーで接続が制限されていますので、\n\n管理者にお問い合わせください。'
}

export default langJapanese
