import React, { useRef, type RefObject, createRef, useState } from 'react'
import AuthLayout from './AuthLayout'
import { AuthWrapper } from '../style'
import { Form, type InputRef, type RefSelectProps } from 'antd'
import CustomButton from 'components/CustomButton'
import { InputItem, RoundedButton, SelectItem } from 'components/commonCustom'
import { idCheckApi, nameCheckApi, recCodeCheckApi, registerApi } from 'apis'
import { useDispatch, useSelector } from 'react-redux'
import types from 'store/actions/types'
import langJapanese from 'common/constants/LangJpAlert'
import TranslateLang from 'utils/translateJp'

type Refs = {
  id: RefObject<InputRef>
  password: RefObject<InputRef>
  rePassword: RefObject<InputRef>
  nickname: RefObject<InputRef>
  phone: RefObject<InputRef>
  bankName: RefObject<RefSelectProps>
  accountNumber: RefObject<InputRef>
  accountHolder: RefObject<InputRef>
  exchangePassword: RefObject<InputRef>
  recommender: RefObject<InputRef>
}

const RegisterModal = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [idCheck, setIdCheck] = useState<boolean>(false)
  const [nameCheck, setNameCheck] = useState<boolean>(false)
  const [recCodeCheck, setRecCodeCheck] = useState<boolean>(false)
  const [disable, setDisable] = useState<boolean>(false)
  const selectRef = useRef('')

  const refs = useRef<Refs>({
    id: createRef(),
    password: createRef(),
    rePassword: createRef(),
    nickname: createRef(),
    phone: createRef(),
    bankName: createRef(),
    accountNumber: createRef(),
    accountHolder: createRef(),
    exchangePassword: createRef(),
    recommender: createRef()
  })

  const options = [
    {
      value: '',
      label: '銀行を選択してください.'
    },
    {
      value: '국민은행',
      label: '국민은행'
    },
    {
      value: '농협',
      label: '농협'
    },
    {
      value: '새마을금고',
      label: '새마을금고'
    },
    {
      value: '신한은행',
      label: '신한은행'
    },
    {
      value: '하나은행',
      label: '하나은행'
    },
    {
      value: '카카오뱅크',
      label: '카카오뱅크'
    },
    {
      value: '신협',
      label: '신협'
    },
    {
      value: '수협',
      label: '수협'
    },
    {
      value: '우리은행',
      label: '우리은행'
    },
    {
      value: '우체국',
      label: '우체국'
    },
    {
      value: '기업은행',
      label: '기업은행'
    },
    {
      value: '대구은행',
      label: '대구은행'
    },
    {
      value: '부산은행',
      label: '부산은행'
    },
    {
      value: '제일은행',
      label: '제일은행'
    },
    {
      value: '씨티은행',
      label: '씨티은행'
    },
    {
      value: '경남은행',
      label: '경남은행'
    },
    {
      value: '산업은행',
      label: '산업은행'
    }
  ]
  const popupStates = useSelector((state: any) => state.waitting)

  const handleSubmit = async (values: any) => {
    if (form.getFieldValue('password') !== form.getFieldValue('repassword')) {
      alert('パスワードが一致しません。')
    }
    // else if (!recCodeCheck) {
    //   alert('추천인을 확인해주세요.')
    // } else if (!nameCheck) {
    //   alert('이름을 확인해주세요.')
    // } else if (!idCheck) {
    //   alert('아이디를 확인해주세요')
    // }
    else {
      if (!selectRef.current) return alert('select 은행명')
      setDisable(true)
      try {
        dispatch({ type: 'WAITTING' })
        const res: any = await registerApi({
          mb_id: values.id,
          mb_password: values.password,
          mb_repassword: values.repassword,
          mb_name: values.nickName,
          mb_hp1: values.phone,
          mb_bank_name: selectRef.current,
          mb_bank_no: values.bankNumber,
          mb_bank_own: values.person,
          mb_exch_pw: values.changePassword,
          mb_rec_id: values.recCode
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(TranslateLang(res.message))
          setNameCheck(true)
          setDisable(false)
          dispatch({ type: types.CLOSE_MODAL })
        } else {
          alert(TranslateLang(res.error.message))
          setDisable(false)
        }
      } catch (error) {
        console.log(error)
        setDisable(false)
      } finally {
        dispatch({ type: 'STOP_WAITTING' })
      }
    }
  }

  const checkId = async () => {
    if (form.getFieldValue('id')) {
      try {
        const res: any = await idCheckApi({
          id: form.getFieldValue('id')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(TranslateLang(res.message))
          setIdCheck(true)
        } else {
          alert(TranslateLang(res.error.message))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkName = async () => {
    if (form.getFieldValue('nickName')) {
      try {
        const res: any = await nameCheckApi({
          name: form.getFieldValue('nickName')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(TranslateLang(res.message))
          setNameCheck(true)
        } else {
          alert(TranslateLang(res.error.message))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const checkRecCode = async () => {
    if (form.getFieldValue('recCode')) {
      try {
        const res: any = await recCodeCheckApi({
          id: form.getFieldValue('recCode')
        })
        // console.log(res);
        if (res.result) {
          // console.log(res);
          alert(TranslateLang(res.message))
          setRecCodeCheck(true)
        } else {
          alert(TranslateLang(res.error.message))
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <AuthLayout>
      <AuthWrapper className='register'>
        <div className='title'>加入申請</div>
        <Form
          name='myForm'
          form={form}
          initialValues={{ remember: true }}
          className='register'
          onFinish={handleSubmit}
        >
          <div className='layout-form'>
            <div className='form-column'>
              <div className='form-item'>
                <label>ID* </label>
                <Form.Item name='id'>
                  <InputItem
                    required
                    ref={refs.current.id}
                    placeholder='英語、数字のみ入力してください。'
                  />
                </Form.Item>
                <div className='rounded-btn'>
                  <RoundedButton type='button' onClick={checkId}>
                    重複確認
                  </RoundedButton>
                </div>
              </div>
              <div className='form-item'>
                <label>パスワード*</label>
                <Form.Item name='password'>
                  <InputItem
                    type='password'
                    required
                    ref={refs.current.password}
                    placeholder='パスワード'
                  />
                </Form.Item>
              </div>
              <div className='form-item'>
                <label>パスワードの再入力*</label>
                <Form.Item name='repassword'>
                  <InputItem
                    type='password'
                    required
                    ref={refs.current.rePassword}
                    placeholder='パスワードの再入力'
                  />
                </Form.Item>
              </div>
              <div className='form-item'>
                <label>ニックネーム*</label>
                <Form.Item name='nickName'>
                  <InputItem
                    required
                    ref={refs.current.nickname}
                    placeholder='ニックネーム入力(2~10)'
                  />
                </Form.Item>
                <div className='rounded-btn'>
                  <RoundedButton type='button' onClick={checkName}>
                    重複確認
                  </RoundedButton>
                </div>
              </div>
              <div className='form-item'>
                <label>携帯番号*</label>
                <Form.Item name='phone'>
                  <InputItem
                    required
                    ref={refs.current.phone}
                    placeholder='携帯番号を入力してください*'
                  />
                </Form.Item>
              </div>
            </div>

            <div className='form-column'>
              <div className='form-item'>
                <label>銀行*</label>
                <SelectItem
                  onChange={(e: any) => (selectRef.current = e)}
                  ref={refs.current.bankName}
                  options={options}
                  defaultValue={''}
                />
              </div>
              <div className='form-item'>
                <label>口座番号*</label>
                <Form.Item name='bankNumber'>
                  <InputItem
                    required
                    ref={refs.current.accountNumber}
                    placeholder='口座番号は数字のみ入力してください。*'
                  />
                </Form.Item>
              </div>
              <div className='form-item'>
                <label>入金者名*</label>
                <Form.Item name='person'>
                  <InputItem
                    required
                    ref={refs.current.accountHolder}
                    placeholder='入金者名*'
                  />
                </Form.Item>
              </div>
              <div className='form-item'>
                <label>出金パスワード*</label>
                <Form.Item name='changePassword'>
                  <InputItem
                    required
                    ref={refs.current.exchangePassword}
                    placeholder=''
                  />
                </Form.Item>
              </div>
              <div className='form-item'>
                <label>推薦人*</label>
                <Form.Item name='recCode'>
                  <InputItem
                    type='password'
                    required
                    ref={refs.current.recommender}
                    placeholder='推薦人確認*'
                  />
                </Form.Item>
                <div className='rounded-btn'>
                  <RoundedButton type='button' onClick={checkRecCode}>
                    重複確認
                  </RoundedButton>
                </div>
              </div>
            </div>
          </div>
          <div className='btn-gr'>
            <CustomButton disabled={popupStates.isWaitting} type='submit'>
              加入申請
            </CustomButton>
          </div>
        </Form>
      </AuthWrapper>
    </AuthLayout>
  )
}

export default RegisterModal
