import { Modal, Input, Select } from 'antd';
import styled from 'styled-components'
import dropDown from 'assets/images/icon/dropdown.png';

export const InputItem = styled(Input)`
  border: 2px solid var(--Gold-stroke, #FEEAC3);
  background: #101010;
  padding: 18px 0 18px 12px;
  color: #fff;
  border-radius: 0 !important;
  line-height: 1.2;
  color: #fff;
  font-size: 20px;
  font-weight: 700;

  &:hover{
    border-color: #FEEAC3 !important;
    background-color: unset !important;
  }
  &:focus{
    border-color: #FEEAC3 !important;
    background-color: unset !important;

  }
  &::placeholder {
    font-weight: bold;
    opacity: 0.5;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    font-size: 12px;
    padding: 9px 0 9px 6px;
  }
`
export const SelectItem = styled(Select)`
  width: 100%;
  height: 64px;
  border-radius: 0 !important;
  border: 2px solid var(--Gold-stroke, #FEEAC3);
  background: #101010;
  .ant-select-selection-item, .ant-select-selection-placeholder{
    font-weight: bold;
    opacity: 0.5;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding-inline-end: 0 !important;
    left: 6px !important; 
    @media (max-width: 768px) {
      font-size: 12px !important;
    }
  }
  .ant-select-selection-item{
    opacity: 1;
    -webkit-text-fill-color: #fff !important;
  }
  .ant-select-selector{
    background-color: transparent !important;
    border-color: transparent !important;
    padding: 0 !important;
    &:after{
      content: url(${dropDown});
      width: 28px;
      visibility: visible;
      line-height: 60px !important;
      @media (max-width: 768px) {
        line-height: 36px !important;
      }

    }
    &:hover{
      border-color: transparent !important;
    }
  }
  @media (max-width: 768px) {
    height: 36px;
  }
`

export const RoundedButton = styled.button`
  border-radius: 30px;
  border: 2px solid var(--Gold-stroke, #FEEAC3);
  background: #101010;
  box-shadow: 0px 0px 21px 8px rgba(142, 0, 0, 0.50) inset;
  padding 18px 0;
  width: 100%;
  background: var(--Gold-stroke, linear-gradient(180deg, #FFB800 0%, #FEEAC3 52.08%, #FFB200 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
 @media (max-width: 768px) {
      padding: 9px;
       font-size: 10px;
      }
`
