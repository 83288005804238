import styled from 'styled-components'
export const Footers = styled.div`
  margin: 70px 0;
  width: 100%;
  font-size: 12px;
  color: #8b8c8f;
  line-height: 19px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  span {
    margin: 0 3px;
    color: #fbbd01;
  }
  .left {
    .left-button {
      background: transparent;
    }
    .left-title {
      text-align: left;
      font-size: 20px;
      color: #ff6f6f;
      font-weight: 700;
      font-family: Inter;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;

    width: 300px;
    img {
      width: 100%;
    }
  }
  .right {
    .right-title {
      color: #ff6f6f;
      text-align: left;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .right-box {
      max-width: 300px;
      text-align: start;
      cursor: pointer;
      span {
        margin-top: 5px;
        color: white;
        margin-right: 10px;
        display: inline-block;
        :hover {
          background: var(
            --Gold-stroke,
            linear-gradient(180deg, #feeac3 0%, #ffe6a6 29.17%, #ffb800 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  @media screen and (max-width: 768px) {
    margin: 20px 0;
  }
`
