/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react' //lazy
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import btnAddValueBlack from 'assets/images/button/btn-addvalue-black.png'
import btnWithDrawBlack from 'assets/images/button/btn-withdraw-black.png'

import btnMessBlack from 'assets/images/button/btn-adminmess-black.png'

import btnMenu from 'assets/images/button/ButtonMenu.png'
import userIcon from 'assets/images/user/user.png'
import { UrlInternal } from 'common/constants/endpoints'
import logo from 'assets/images/login/logo.png'
// import logo from 'assets/images/login/World_Logo.png'
// import { Popover, Tooltip } from 'antd'
import './style.css'
import avatar from 'assets/images/avatar.png'
import * as S from './style'
import changePoint from 'assets/images/Icon.png'
import types from 'store/actions/types'
import CustomButton from 'components/CustomButton'
import { isEmptyObject } from 'utils/ref'
import MobileHeader from 'components/MobileHeader'
import moneyConvert from 'utils/moneyConvert'
import {
  IconDeposit,
  IconEvent,
  IconHoverDeposit,
  IconHoverEvent,
  IconHoverMessage,
  IconHoverNoti,
  IconHoverPoint,
  IconHoverWitdhDraw,
  IconMessage,
  IconNoti,
  IconPoint,
  IconWitdhDraw
} from 'common/constants/svg'
interface Props {
  onClick?: any
  className?: any
}

const HeaderPage = (props: Props) => {
  const history = useHistory()
  const { onClick, className } = props
  const dataMe = useSelector((state: any) => state.user)
  const [onShowMenu, setOnShowMenu] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleOpenModal = (name: string) => {
    dispatch({ type: types.OPEN_MODAL, payload: { name: name } })
  }
  const handleShow = () => {
    setOnShowMenu(!onShowMenu)
  }
  const Logout = () => {
    localStorage.removeItem('User')
    localStorage.removeItem('Popup')
    dispatch({
      type: types.GET_DATA_USER,
      dataUser: {}
    })
    history.push(UrlInternal.HOME)
  }

  const activeTab = (name: string) => {
    return history.location.pathname === name
  }

  const content = (
    <div style={{ margin: '0px 10px', minWidth: '250px' }}>
      <S.Row>
        {dataMe.dataUser && (
          <S.Avatar onClick={() => history.push(UrlInternal.USER)}>
            <S.AvatarImg>
              <img src={avatar} alt='avatar' />
            </S.AvatarImg>
            <p style={{ color: '#FAAD14' }}>{dataMe.dataUser.mb_name}</p>
          </S.Avatar>
        )}
        <S.Info>
          <p>
            보유머니: <span>{dataMe.dataUser.mb_money}</span> 원
          </p>
          <S.BoxItemP onClick={() => history.push(UrlInternal.POINT)}>
            포인트: <span>{dataMe.dataUser.mb_point}</span> 점
            <img src={changePoint} alt='changePoint' /> 전환
          </S.BoxItemP>
        </S.Info>
      </S.Row>
      <S.Menu>
        <S.Content onClick={() => history.push(UrlInternal.CUSTOMER_CENTER)}>
          <p style={{ color: 'yellow' }}>고객센터</p>
        </S.Content>
        <S.Content onClick={() => history.push(UrlInternal.MESSAGE)}>
          <p style={{ color: 'yellow' }}>쪽지</p>
        </S.Content>
        <S.ContentLogOut onClick={Logout}>
          <p style={{ color: 'red' }}>로그아웃</p>
        </S.ContentLogOut>
      </S.Menu>
    </div>
  )

  return (
    <>
      <S.NewWrapper>
        <S.WrapperAuth>
          {isEmptyObject(dataMe.dataUser) ? (
            <>
              <CustomButton onClick={() => handleOpenModal('login')}>
                ログイン
              </CustomButton>
              <CustomButton onClick={() => handleOpenModal('register')}>
                {/* 회원가입 */}
                加入申請
              </CustomButton>
            </>
          ) : (
            <>
              <S.InfoUser>
                <div className='cash-info'>
                  <div className='item'>
                    <div className='title'>保有金*</div>
                    <div className='value'>
                      {moneyConvert(dataMe.dataUser.mb_money)} 円
                    </div>
                  </div>
                  <div className='item'>
                    <div className='title'>ポイント*</div>
                    <div className='value'>
                      {moneyConvert(dataMe.dataUser.mb_point)} 円
                    </div>
                  </div>
                </div>
              </S.InfoUser>
              <S.UserTag onClick={() => history.push(UrlInternal.USER)}>
                <div className='icon'>
                  <img src={userIcon} alt='' />
                </div>
                <span>{dataMe.dataUser.mb_name}</span>
                {/* <div className='cash-info'>
                <div className='item'>
                  <div className='title'>보유머니*</div>
                  <div className='value'>{dataMe.dataUser.mb_money} 원</div>
                </div>
                <div className='item'>
                  <div className='title'>포인트*</div>
                  <div className='value'>{dataMe.dataUser.mb_point} 점</div>
                </div>
              </div> */}
              </S.UserTag>
              <CustomButton onClick={() => Logout()}>ログアウト</CustomButton>
            </>
          )}
        </S.WrapperAuth>
      </S.NewWrapper>
      <S.Header className='navbar-overlay'>
        {/* <S.Wrapper> */}
        <S.WrapperTop>
          <S.MenuWraper>
            {/* <MenuOutlined
            style={{ fontSize: '32px', color: '#08c' }}
            onClick={handleShow}
          > */}
            <S.BtnMenu onClick={handleShow}>
              <img alt='' src={btnMenu} />
            </S.BtnMenu>
            {/* </MenuOutlined> */}
          </S.MenuWraper>

          <S.MenuGroupList
            onClick={handleShow}
            show={onShowMenu}
          ></S.MenuGroupList>
          <S.TopButton show={onShowMenu}>
            <S.BoxItem
              className={
                activeTab(UrlInternal.DEPOSIT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.DEPOSIT)}
            >
              <div className='img-box'>
                <div className='simple'>{IconDeposit}</div>
                <div className='hover'>{IconHoverDeposit}</div>

                <span>入金</span>
              </div>

              <img className='active-img' alt='' src={btnAddValueBlack} />
            </S.BoxItem>

            <S.BoxItem
              className={
                activeTab(UrlInternal.PAYMENT)
                  ? 'active content-top large-icon'
                  : 'content-top large-icon'
              }
              onClick={() => history.push(UrlInternal.PAYMENT)}
            >
              <div className='img-box'>
                <div className='simple'>{IconWitdhDraw}</div>
                <div className='hover'>{IconHoverWitdhDraw}</div>
                <span>出金</span>
              </div>

              <img className='active-img' alt='' src={btnWithDrawBlack} />
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.MESSAGE)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.MESSAGE)}
            >
              <div className='img-box'>
                <div className='simple'>{IconMessage}</div>
                <div className='hover'>{IconHoverMessage}</div>
                <span>メッセージ</span>
              </div>
              <img className='active-img' alt='' src={btnMessBlack} />
            </S.BoxItem>
            <S.TopLogo onClick={() => history.push(UrlInternal.HOME)}>
              <img alt='logo' src={logo} />
            </S.TopLogo>
            {/* {dataMe?.dataUser?.mb_option_rec === '1' && (
              <S.BoxItem
                className='content-top'
                onClick={() => window.open(UrlInternal.PARTNER)}
              >
                <div className='img-box'>
                  <img alt='' src={btnPartner} />
                  <span>파트너</span>
                </div>
                <img className='active-img' alt='' src={btnPartnerBlack} />
              </S.BoxItem>
            )} */}

            <S.BoxItem
              className={
                activeTab(UrlInternal.NOTIFICATION)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.NOTIFICATION)}
            >
              <div className='img-box'>
                <div className='simple'>{IconNoti}</div>
                <div className='hover'>{IconHoverNoti}</div>
                <span>お知らせ</span>
              </div>
            </S.BoxItem>
            <S.BoxItem
              className={
                activeTab(UrlInternal.EVENT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.EVENT)}
            >
              <div className='img-box event'>
                <div className='simple'>{IconEvent}</div>
                <div className='hover'>{IconHoverEvent}</div>
                <span>イベント</span>
              </div>
            </S.BoxItem>

            <S.BoxItem
              className={
                activeTab(UrlInternal.POINT)
                  ? 'active content-top'
                  : 'content-top'
              }
              onClick={() => history.push(UrlInternal.POINT)}
            >
              <div className='img-box'>
                <div className='simple'>{IconPoint}</div>
                <div className='hover'>{IconHoverPoint}</div>
                <span>ポイント交換</span>
              </div>
            </S.BoxItem>
          </S.TopButton>
        </S.WrapperTop>
      </S.Header>
      <MobileHeader />
    </>
  )
}

export default HeaderPage
