import types from '../actions/types'

const defaultState = {
  dataUser: {},
  message: ''
}

export default (state = defaultState, action: any) => {
  switch (action.type) {
    case types.GET_DATA_USER:
      return {
        ...state,
        dataUser: action.dataUser
      }
    case types.GET_MESSAGE_CONF:
      return {
        ...state,
        message: action.messageCof
      }
    default:
      return state
  }
}
